.wedgeBanner {
  background-image: url('/images/signin-banner.jpg'), linear-gradient(#1E2C20, #1B3025);
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: polygon(0 0, 96% 0%, 60% 100%, 0% 100%);
}

.logo {
  position: relative;
  margin-top: 38px;
  margin-left: 40px;
  width: 28%
}

@media screen and (max-width: 768px) {
  .wedgeBanner {
    background-image: url('/images/signin-banner-mobile.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    clip-path: none;
  }

  .logo {
    width: 36%;
    margin-top: 30px;
    margin-left: 16px;
  }
}

@media screen and (max-width: 480px) {
  .wedgeBanner {
    background-image: url('/images/signin-banner-mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: none;
  }
}

